import React, { useEffect, useState } from 'react'
import './styles/passwordreset.css'
import Swal from 'sweetalert2';
import adminServices from '../services/adminServices';
import service from '../services/service';
import UserService from '../services/UserService';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export default function PasswordReset() {

    const [data, setData] = useState();
    const [captchaHash, setCaptchaHash] = useState();
    const [captchaToken, setCaptchaToken] = useState();
    const [captchaKey, setCaptchaKey] = useState();
    const [captchaInput, setCaptchaInput] = useState();
    const [showOtp, setshowOtp] = useState(false);
    const [otp, setOtp] = useState({
        otpId: "",
        otp: "",
        otpError: "",
    });
    const history = useHistory();

    useEffect(() => {
        service.getRegisterCaptcha().then((res) => {
            setData(res.data.captchaImage);
            setCaptchaHash(res.data.captchaHash);
            setCaptchaToken(res.data.captchaToken);
            setCaptchaKey(res.data.captchaKey);
        });
    }, [])

    const handleRefereshCaptcha = () => {
        service.getRegisterCaptcha(captchaKey).then((res) => {
            setData(res.data.captchaImage);
            setCaptchaHash(res.data.captchaHash);
            setCaptchaToken(res.data.captchaToken);
            setCaptchaKey(res.data.captchaKey);
        });
    };

    function handleCaptcha(e) {
        setCaptchaInput(e.target.value);
    }

    const [passwordReset, setPasswordReset] = useState({
        showModal: false,
        email: "",
        // mobile: "",

    });

    const [passwordResetError, setPasswordResetError] = useState({
        emailError: "",
        captchaError: "",
    })


    const passwordValidationCheck = () => {
        let emailErr = ""
        let captchaErr = ""

        if (!passwordReset.email) {
            emailErr = "Email is Required"
        }
        else if (!(passwordReset.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/))) {
            emailErr = "Enter Valid Email Id"
        }
        if (!captchaInput) {
            captchaErr = "Captcha is Required"
        }
        if (emailErr || captchaErr) {
            setPasswordResetError({
                ...passwordResetError,
                emailError: emailErr,
                captchaError: captchaErr
            })
            return false
        }
        return true
    }

    const otpValidation = () => {
        let otpErr = ""

        if (!otp.otp) {
            otpErr = "OTP is Required"
        }
        else if (!(otp.otp.match(/^[0-9]{6}$/))) {
            otpErr = "Enter Valid OTP "
        }
        if (otpErr) {
            setOtp({
                ...otp,
                otpError: otpErr,
            })
            return false
        }
        return true
    }



    const sendOtpMailOnClick = () => {
        const data = {
            email: passwordReset.email,
            // mobile: passwordReset.mobile,
            captchaHash: captchaHash,
            captchaInput: captchaInput,
            captchaToken: captchaToken,
            captchaKey: captchaKey
        }
        const status = passwordValidationCheck();

        if (status) {

            Swal.fire({
                title: "Please Wait",
                text: "Otp is being sent to your registered email id",
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                    adminServices.sendMailForVerifyOtp(data).then((resp) => {
                        
                        if (resp.data == "email_not_exit" || resp.data == "mobile_not_match") {
                            Swal.fire({
                                icon: 'error',
                                title: 'Invalid email or Mobile Number',
                                showConfirmButton: false,
                                timer: 3000
                            })
                        }
                        // else if (resp.data == "mobile_not_match") {
                        //     Swal.fire({
                        //         icon: 'error',
                        //         title: 'Mobile No. Not Attached with Mail Id',
                        //         showConfirmButton: false,
                        //         timer: 3000
                        //     })
                        // }
                        else if (resp.data == "invalid_captcha") {
                            Swal.fire({
                                icon: 'error',
                                title: 'Invalid Captcha Error',
                                showConfirmButton: false,
                                timer: 3000
                            })
                            handleRefereshCaptcha();
                        }
                        else {
                            Swal.fire({
                                icon: 'success',
                                title: 'OTP Sent Successfully',
                                showConfirmButton: false,
                                timer: 3000
                            })
                            // setData();
                            setshowOtp(true)
                            setOtp({
                                ...otp,
                                otpId: resp.data.otpId,
                            })
                            // handleRefereshCaptcha()
                            setshowOtp(true)
                            // setCaptchaHash();
                            // setCaptchaToken();
                            // setCaptchaKey();
                            // setCaptchaInput();
                            // setPasswordReset({
                            //     email: "",
                            //     mobile: "",
                            //     showModal: false
                            // })
                            setPasswordResetError({
                                emailError: "",
                                // mobileError: ""
                            })
                        }

                    }).catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Something Went Wrong',
                            showConfirmButton: false,
                            timer: 3000
                        })
                        setPasswordReset({
                            email: "",
                            // mobile: "",
                            showModal: false
                        })
                        setPasswordResetError({
                            emailError: "",
                            // mobileError: ""
                        })
                        setshowOtp(false)
                        setOtp(prev => {
                            return {
                                ...prev,
                                otpId: "",
                                otp: "",
                                otpError: ""
                            }
                        })
                        setOtp(prev => {
                            return {
                                ...prev,
                                otpId: "",
                            }
                        })
                        handleRefereshCaptcha()
                        setCaptchaHash();
                        setCaptchaToken();
                        setCaptchaKey();
                        setCaptchaInput();
                    })
                }
            })

        }
    }


    const sendResetPasswordOnClick = () => {
        const data = {
            otp: otp.otp,
            otpId: otp.otpId,
        }
        const status = otpValidation();

        if (status) {
            // Swal.fire({
            //     title: "Do you want to reset your password",
            //     showDenyButton: true,
            //     confirmButtonColor: "#11B67A",
            //     confirmButtonText: "Confirm",
            //     denyButtonText: `Cancel`
            // }).then((response) => {
            //     if (response.isConfirmed) {
            Swal.fire({
                title: "Please Wait",
                text: "Otp verification is in progress",
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                    adminServices.sendMailForPasswordChange(data).then((resp) => {
                        
                        if (resp.data == "success") {
                            Swal.fire({
                                icon: 'success',
                                title: 'Password Sent Successfully to your registered email-Id',
                                showConfirmButton: false,
                                timer: 3000
                            })
                            handleRefereshCaptcha()
                            setshowOtp(false)
                            setOtp(prev => {
                                return {
                                    ...prev,
                                    otpId: "",
                                    otp: "",
                                    otpError: ""
                                }
                            })
                            setCaptchaHash();
                            setCaptchaToken();
                            setCaptchaKey();
                            setCaptchaInput();
                            setPasswordReset({
                                email: "",
                                // mobile: "",
                                showModal: false
                            })
                            setPasswordResetError({
                                emailError: "",
                                // mobileError: ""
                            })
                        }

                        if(resp.data == "invalid_otp"){
                            Swal.fire({
                                icon: 'error',
                                title: 'Kindly enter valid OTP',
                                showConfirmButton: false,
                                timer: 3000
                            })

                        }

                    }).catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Something Went Wrong',
                            showConfirmButton: false,
                            timer: 3000
                        })
                        setPasswordReset({
                            email: "",
                            // mobile: "",
                            showModal: false
                        })
                        setPasswordResetError({
                            emailError: "",
                            // mobileError: ""
                        })
                        setOtp(prev => {
                            return {
                                ...prev,
                                show: false,
                                otpId: "",
                                otp: ""
                            }
                        })
                        setshowOtp(false)
                        setOtp(prev => {
                            return {
                                ...prev,
                                otpId: "",
                                otp: "",
                                otpError: ""
                            }
                        })
                        handleRefereshCaptcha()
                        setCaptchaHash();
                        setCaptchaToken();
                        setCaptchaKey();
                        setCaptchaInput();
                    })
                }
            })

            // }
            // })
        }
    }

    const redirectToHome = () => {
        history.push(`${process.env.PUBLIC_URL}`)
    }

    return (
        <>
            <div className='passwordReset'>
                <div className='backgroundColour'>
                    <div className='container'>
                        <div className='row'>
                            <div class="col-md-6 mx-auto">
                                <div className='mt-4 text-center'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/ppa-logo-text.png`} />
                                </div>
                                <div class="card mt-5">
                                    <div className='strip'>
                                    </div>
                                    <div class="card-body">
                                        <div className='card-title text-center'>
                                            <h5> Reset Password </h5>
                                        </div>
                                        <div className='modal-login-area'>
                                            <div className='input-area' >
                                                <p className="input-text" > Enter E-Mail ID</p>
                                                <input className='input-field' value={passwordReset.email} disabled={otp.otpId ? true : false} onChange={(e) => setPasswordReset(prev => {
                                                    return {
                                                        ...prev,
                                                        email: e.target.value
                                                    }
                                                })} placeholder='E-mail ID' />
                                                {
                                                    passwordResetError.emailError
                                                        ?
                                                        <div className="alert alert-danger mt-2">{passwordResetError.emailError}</div>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                {/* <p className="input-text" > Enter Mobile Number</p>
                                                <input className='input-field' minLength={10} maxLength={10} disabled={otp.otpId ? true : false} value={passwordReset.mobile} onChange={(e) => setPasswordReset(prev => {
                                                    return {
                                                        ...prev,
                                                        mobile: e.target.value
                                                    }
                                                })} placeholder='Mobile No.' />
                                                {
                                                    passwordResetError.mobileError
                                                        ?
                                                        <div className="alert alert-danger mt-2">{passwordResetError.mobileError}</div>
                                                        :
                                                        <>
                                                        </>
                                                } */}
                                                <div className="row text-center">
                                                    <div className="col col-md-4">
                                                        <img className='image-captcha' src={`data:image/jpeg;base64,${data}`} style={{ height: "50px" }} />
                                                    </div>
                                                    <div className="col md-col-4">
                                                        <button className="btn refresh" disabled={otp.otpId ? true : false} onClick={handleRefereshCaptcha}>
                                                            <i class="fa fa-refresh" style={{ color: "white" }}></i>
                                                        </button>
                                                    </div>
                                                    <div className="col col-md-4">
                                                        {/* <p className="input-text">Enter Captcha </p> */}
                                                        <input className='input-field' disabled={otp.otpId ? true : false} type="text" placeholder="Captcha" id="registration_captcha" onChange={(e) => handleCaptcha(e)} minLength="4" maxLength="7" />
                                                        <span className="registration_input-msg" style={{ color: "red" }}></span>
                                                        {
                                                            passwordResetError.captchaError
                                                                ?
                                                                <div className="alert alert-danger mt-2">{passwordResetError.captchaError}</div>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </div>


                                                </div>
                                                {
                                                    showOtp ?
                                                        <>
                                                            <br />
                                                            <p className="input-text" > OTP ID - <strong>{otp.otpId}</strong></p>
                                                            <br />
                                                            <p className="input-text" > Enter OTP </p>
                                                            <input className='input-field' minLength={6} maxLength={6} value={otp.otp} onChange={(e) => setOtp({
                                                                ...otp,
                                                                otp: e.target.value
                                                            })} placeholder='Enter OTP' />
                                                            {
                                                                otp.otpError
                                                                    ?
                                                                    <div className="alert alert-danger mt-2">{otp.otpError}</div>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                {
                                                    showOtp ?
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <button onClick={() => {
                                                                    sendResetPasswordOnClick()
                                                                }} className='send-otp'>Submit</button>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='row'>
                                                            <div className='col col-md-12'>
                                                                <button onClick={() => {
                                                                    sendOtpMailOnClick()
                                                                }} className='send-otp'>Send OTP</button>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='text-right'>
                                            <Link className="text-center" onClick={() => {
                                                redirectToHome();
                                            }} >Return to Home</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
