import React, { useState } from 'react';
import "./ContentDeliverySidebar.css";
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Truncate from 'react-truncate';

const ContentDeliverySidebar = ({
    mergedCourseStructure,
    sideBarOpen,
    setSideBarOpen,
    selectedContent,
    handleNodeClick,
}) => {



    const [expandedFolder, setExpandedFolder] = useState(null);
    const [totalDuration, setTotalDuration] = useState(0);

    const handleFolderClick = (folderId) => {
        if (expandedFolder === folderId) {
            setExpandedFolder(null);
        } else {
            setExpandedFolder(folderId);
        }
    };

    const sumOfAllContentDuration = (nodes) => {
        let totalDuration = 0;
        nodes.forEach(node => {
            if (node.nodetype === "folder") {
                totalDuration += sumOfAllContentDuration(node.nodes);
            } else {
                totalDuration += node.duration ?? 0;
            }
        });
        return totalDuration;
    };

    const renderIcons = (node) => {

        let icon;
        switch (node.nodetype) {
            case 'youtube':
                icon = <i className="fa fa-youtube-play" aria-hidden="true"></i>;
                break;
            case 'vlabs':
                icon = <i className="fa fa-link" aria-hidden="true"></i>;
                break;
            case 'mp4':
                icon = <i className="fa fa-video-camera" aria-hidden="true"></i>;
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
                icon = <i className="fa fa-picture-o" aria-hidden="true"></i>;
                break;
            case 'html':
                icon = <i className="fa fa-code" aria-hidden="true"></i>;
                break;
            case 'scrom':
            case 'zip':
                icon = <i className="fa fa-file-archive-o" aria-hidden="true"></i>;
                break;
            case 'practiceQuiz':
                icon = <i className="fa fa-question" aria-hidden="true"></i>;
                break;
            case 'pdf':
                icon = <i className="fa fa-file-pdf-o" aria-hidden="true"></i>;
                break;
            case 'streaming':
                icon = <i className="fa fa-file-video-o" aria-hidden="true"></i>;
                break;
            case 'externalLink':
                icon = <i className="fa fa-external-link" aria-hidden="true"></i>;
                break;
            default:
                icon = <i className="fa fa-file" aria-hidden="true"></i>;
        }

        return icon;
    }


    const renderContents = (nodes, folderId) => {
        if (!nodes || !expandedFolder || folderId !== expandedFolder) return null;

        return (
            <div className={`contents ${expandedFolder === folderId ? 'show' : ''}`}>
                {nodes.map(node => (
                    <div
                        key={node.id}
                        className={`${selectedContent?.id === node.id ? "content-node-selected" : "content-node"}`}
                        onClick={() => {
                            handleNodeClick(node);
                            // callUpdateContentOutTimeToggler();
                        }}
                    >
                        {renderIcons(node)}
                        <div className="content-details">
                            <div className="content-label">
                                <OverlayTrigger
                                     placement='bottom'
                                    overlay={
                                        <Tooltip
                                            id=""
                                            style={{ fontSize: '18px' }}
                                        >
                                            {node.label}
                                        </Tooltip>
                                    }
                                >
                                    <span id='label-name'>
                                        <Truncate
                                            lines={1}
                                            width={150}
                                            ellipsis={"...."}
                                        >
                                            {node.label}
                                        </Truncate>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="content-duration">{node.duration} mins</div>
                        </div>
                        <div className="content-progressbar">
                            <CircularProgressbar
                                value={node.activityProgress}
                                strokeWidth={50}
                                styles={buildStyles({
                                    strokeLinecap: "butt",
                                    pathColor: "#f0ad4e",
                                })}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderFolders = (nodes) => {

        return nodes?.map(node => {
            const isSelected = expandedFolder === node.id;
            return (
                <div
                    key={node.id}
                    className={`folder-and-contents`}
                >
                    <div
                        className={`folder-node ${isSelected ? 'selected' : ''}`}
                        onClick={() => {
                            handleFolderClick(node.id);
                            // callUpdateContentOutTimeToggler();
                        }}
                    >
                        <Row style={{ marginTop: "-3px"}}>
                            <Col xs={8} lg={8}>
                                <i class={`${isSelected ? 'fa fa-folder-open' : 'fa fa-folder'}`} aria-hidden="true"></i>
                                <span className="folder-label">
                                    <OverlayTrigger
                                         placement='bottom'
                                        overlay={
                                            <Tooltip
                                                id=""
                                                style={{ fontSize: '18px' }}
                                            >
                                                {node.label}
                                            </Tooltip>
                                        }
                                    >
                                        <span id='label-name'>
                                            <Truncate
                                                lines={1}
                                                width={150}
                                                ellipsis={"...."}
                                            >
                                                {node.label}
                                            </Truncate>
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            </Col>
                            <Col xs={2} lg={2}>
                                <span className="folder-duration">{sumOfAllContentDuration(node.nodes)} mins</span>
                            </Col>
                            <Col xs={2} lg={2}>
                                <span className={`dropdown-icon ${expandedFolder === node.id ? "expanded" : ""}`}>
                                    <i className={`fa fa-chevron-circle-down`}></i>
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <div className={`content-delivery-sidebar-folder-contents ${expandedFolder === node.id ? 'show' : ''}`}>
                        {expandedFolder === node.id && renderContents(node.nodes, node.id)}
                    </div>
                </div>
            );
        });
    };

    return (
        <div id='content-delivery-sidebar-main' className={`content-delivery-sidebar ${sideBarOpen ? "open" : ""}`}>
            <div id='content-delivery-sidebar-main' className='sidebar-contents'>
                {renderFolders(mergedCourseStructure?.nodes)}
            </div>
        </div>
    );
}

export default ContentDeliverySidebar