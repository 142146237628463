import React, { useState, useEffect } from "react";
import StickyMenu from "../../components/common/StickyMenu";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import Sidebar from "../shared/Sidebar";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import UserService from "../../services/UserService";
import FilterDataTable from "../../pages/instructor/FilterDataTable";
import adminServices from "../../services/adminServices";
import learnerService from "../../services/learnerService";
import service, { USER_API } from "../../services/service";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Services from '../../../src/services/service';
import fileDownload from 'js-file-download';
import swal from 'sweetalert';
import { saveAs } from 'file-saver';

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};

function SingleLearnerReportCard(props) {



  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const um_api = USER_API;

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


  const [submitLoading, setSubmitLoading] = useState({
    isLoading: false,
  });

  const [courseState, setCourseState] = useState([]);
  let userId = props.match.params.id;
  let roleId = 1;
  useEffect(() => {
      Services.getCoursesandProgressEnrolledByUser(userId, roleId)
          .then(res => {
              setCourseState(res.data);
          })
          .catch(err => {
             
          })
  }, [])

  const [getFileURL, setFileURL] = useState(null);
  const [getCertificateGenerateStatus, setCertificateGenerateStatus] = useState(false);

  const handlePaymentSlipView = async(userId, courseId) => {

    try {
      const response = await service.paymentReceiptforadmin(userId, courseId);

      saveAs(response.data, "Payment Slip" + ".pdf")
    } catch (error) {
      if(error == "payment-slip-does-not-exist"){

        swal("Information", "Payment Slip does not Exist", "info");

      }
      console.error("error", error)
    }

  }

  const handleCertificateView = (userId, courseId, firstName) => {
    let tenantId = 1;
    certificateDownloadFunction(userId, courseId, tenantId, firstName);
    //setCertificateView({ show: true })
}

const certificateDownloadFunction = (userId, courseId, tenantId, firstName) => {

  setFileURL(null);

  learnerService.toGenerateCertificate(userId, courseId, tenantId)
      .then(res => {
          
          if (res.status == '200') {
              // return(
              //     <>
              //     <a target='_blank' src={`${CERTIFICATION}/certificate/downloadFile/${courseId}/${res.data}`}>test</a>
              //     </>
              // )

              learnerService.toDownloadCertificate(courseId, res.data)
                  .then(res => {
                      
                      fileDownload(res.data, `${firstName} Certificate.pdf`);
                      setCertificateGenerateStatus(!getCertificateGenerateStatus);
                  })
                  .catch(err => {

                  })

              
              //setCertificateUrl(res.data);


              // var file = new File([res.data], "tempName.pdf", {
              //     type: "application/pdf"
              //   });
              //   const fileURL = URL.createObjectURL(file);
              
              //   setFileURL(fileURL);
          }

      }).catch(err => {
          swal(t('error_mesg'), t('try_sometimes'), "error");
      })

}

  const filteredItems = courseState.sort((a, b) => new Date(b.commencementDate) - new Date(a.commencementDate));

  const columns = [
    {
        name: "S.No",
        selector: (row, index) => index + 1,
        width: '100px',
        sortable: true,
    },
    
    {
        name: "Course Enrolled",
        selector: row => `${row.courseDetails.courseName}`,
        sortable: true,
        wrap: true,
        //width: '150px',
    },
    {
        name: "Type and Fees",
        selector: row => `${row.courseDetails.courseType} (${row.courseDetails.courseFee})`,
        wrap: true,
        sortable: true,
    },
    {
      name: "Course Status and Progess Percentage",
      selector: row => `${row.userCourseProgressDTO.progressStatus} (${row.userCourseProgressDTO.progress}%)`,
      wrap: true,
      sortable: true,
  },
    // {
    //     name: "instructor",
    //     selector: row => (row.instructor != null)? `${row.instructor[0]?.firstName} ${row.instructor[0]?.lastName}`: "",
    //     sortable: true,
    // },
    {
      name: "Certificate",
      cell: (row) => (
          (row.userCourseProgressDTO.progressStatus == 'COMPLETED') ? (<div>
              <Button onClick={() => {handleCertificateView(row.id.userId, row.id.courseId, row.userCourseProgressDTO.firstName)}} style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)', borderRadius: '5px', border: '0px' }}> 
              <i class="las la-certificate la-2x" style={{ color: 'white' }}></i> 
              </Button>
          </div>):(
              <div>
                  <Button  style={{ background: 'linear-gradient(60deg, #cccccc 0%, #aaaaaa 100%)', borderRadius: '5px', border: '0px' }}> 
              <i class="las la-certificate la-2x" style={{ color: 'white' }}></i> 
              </Button>
              </div>
          )
      ),
      wrap: true,
      sortable: true,
  },
  {
    name: "Payment Slip",
    cell: (row) => (
        (row.courseDetails.courseFee != '0') ? (<div>
            <Button onClick={() => {handlePaymentSlipView(row.id.userId, row.id.courseId)}} style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)', borderRadius: '5px', border: '0px' }}> 
            <i class="las la-receipt la-2x" style={{ color: 'white' }}></i> 
            </Button>
        </div>):(
            <div>
                <Button  style={{ background: 'linear-gradient(60deg, #cccccc 0%, #aaaaaa 100%)', borderRadius: '5px', border: '0px' }}> 
            <i class="las la-receipt la-2x" style={{ color: 'white' }}></i> 
            </Button>
            </div>
        )
    ),
    wrap: true,
    sortable: true,
},
];
  


  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div>
              <div className="page-header">
                <h3 className="page-title">Single Learners Report</h3>
              </div>
              <Col lg="12">
                
                <div className="card">
                <div className="card-body" style={{backgroundColor:"#e1e1e1"}}>
                  <h4> Learner : {courseState[0]?.userCourseProgressDTO.firstName}  {courseState[0]?.userCourseProgressDTO.lastName}
                  {" | "} Email   : {courseState[0]?.userCourseProgressDTO.email} </h4>
                  </div>
                
                <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        defaultSortField="Name"
                                        defaultSortAsc={true}
                                        striped
                                        pagination
                                        highlightOnHover
                                        customStyles={customStyles}
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                    />
                                </div>
                </div>
              </Col>
            </div>
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default SingleLearnerReportCard;
